import React, {
    Fragment
} from "react";
import VerbListItem from "./VerbListItem";


const VerbList = ({verbs}) => {
    if (verbs.length > 0) {
        verbs = verbs.map((verbData) => (<VerbListItem key={verbData.uuid}
                                                       uuid={verbData.uuid}
                                                       verb={verbData.verb}/>));
    }

    return (
        <Fragment>
            <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                    <thead className="bg-gray-50 dark:bg-gray-800">
                    <tr>
                        <th scope="col"
                            className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <div className="flex items-center gap-x-3">
                                <span className='uppercase'>uuid</span>
                            </div>
                        </th>

                        <th scope="col"
                            className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <div className="flex items-center gap-x-3">
                                <span className='uppercase'>verb</span>
                            </div>
                        </th>

                        <th scope="col" className="relative py-3.5 px-4">
                            <span className="sr-only">Edit</span>
                        </th>
                    </tr>
                    </thead>
                    <tbody
                        className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                    {
                        (verbs.length > 0) && verbs
                    }
                    </tbody>
                </table>
            </div>
        </Fragment>
    );
}

export default VerbList;