import React, {
    Fragment
} from "react";
import {Outlet} from "react-router-dom";

const AuthRoot = () => {
    return (
        <Fragment>
            <Outlet/>
        </Fragment>
    );
};

export default AuthRoot;
