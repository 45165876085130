class SentenceData {
    constructor({uuid, phrasalVerbUuid, en, tr}) {
        this.uuid = uuid;
        this.phrasalVerbUuid = phrasalVerbUuid;
        this.en = en;
        this.tr = tr;
    }

    static fromDocumentSnapshot = (documentSnapshot) => {
        return new SentenceData({
            uuid: documentSnapshot.data()['uuid'],
            phrasalVerbUuid: documentSnapshot.data()['phrasal_verb_uuid'],
            en: documentSnapshot.data()['en'],
            tr: documentSnapshot.data()['tr'],
        });
    }
}

export default SentenceData;