import {redirect} from "react-router-dom";
import {homeAuthSignInRoute} from "../route-generators";
import {isSignedIn} from "../../services/auth";

export const loader = () => {
    const authData = isSignedIn();

    if (!authData.isAuthenticated) {
        return redirect(homeAuthSignInRoute());
    }

    return null;
}