import React, {
    Fragment
} from "react";

import logo from '../assets/images/logo.png';
import Carousel from "../components/Carousel";
import NavBar from "../components/NavBar";
import {useLoaderData} from "react-router-dom";

const HomePage = () => {
    const loaderData = useLoaderData();

    return (
        <Fragment>
            <div className='flex flex-col h-screen'>
                <NavBar isAuthenticated={loaderData.isAuthenticated}/>
                <div className='flex md:flex-row flex-col md:h-full mt-5 md:mt-0 justify-center items-center'>
                    <div className='md:w-1/2 w-full flex justify-center'>
                        <Carousel/>
                    </div>
                    <div className='md:w-1/2 w-full'>
                        <Content/>
                    </div>
                </div>
            </div>

        </Fragment>
    );
};

export default HomePage;

const Content = () => {
    return (
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
            <div className="max-w-lg sm:text-center sm:mx-auto">
                <a className="inline-block mb-4"
                   href="/src/pages"
                   aria-label="Go Home"
                   title="Logo">
                    <img className='w-[100px] h-[100px] border rounded-full'
                         src={logo} alt='App Logo'/>
                </a>
                <h2 className="mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                    İngilizce kelime ezberlemenin adresi
                </h2>
                <p className="text-base text-gray-700 md:text-lg">
                    İngilizcede sık kullanılan 'phrasal verb'leri ingilizce ve türkçe açıklamaları ile karşılaştırarak
                    öğrenin. Örnek cümlelerde kullanımlarına bakarak hafızanıza kazıyın.
                </p>
                <hr className="my-8 border-gray-300"/>
                <div className="flex items-center mb-3 sm:justify-center">
                    <a className="transition duration-300 hover:shadow-lg"
                       href="https://play.google.com/store/apps/details?id=com.umutyenidil.verbly"
                       target='_blank'>
                        <img
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/1000px-Google_Play_Store_badge_EN.svg.png"
                            className="object-cover object-top w-32 mx-auto"
                            alt=""
                        />
                    </a>
                </div>
                <p className="max-w-xs text-xs text-gray-600 sm:text-sm sm:max-w-sm sm:mx-auto">
                    800'den fazla örnek cümle
                </p>
            </div>
        </div>
    );
};