import {deleteVerb} from "../../services/remote-storage";
import {json, redirect} from "react-router-dom";
import {FAILED_OPERATION, SUCCESSFUL_OPERATION} from "../../constants/operation-status-constants";
import store from "../../store/store";
import {pageMessageActions} from "../../store/page-message-slice";
import {ERROR_MESSAGE, SUCCESS_MESSAGE} from "../../constants/page-message-constants";
import {homeDashboardVerbsUuidDeleteRoute} from "../route-generators";

export const action = async ({params}) => {

    const result = await deleteVerb(params.uuid);

    store.dispatch(pageMessageActions.setMessage({
        message: result.status === FAILED_OPERATION ? result.error : 'Verb basariyla silindi',
        type: result.status === FAILED_OPERATION ? ERROR_MESSAGE : SUCCESS_MESSAGE,
        from: homeDashboardVerbsUuidDeleteRoute(params.uuid),
    }));

    return null;
}