import React, {
    Fragment
} from "react";
import {Link, NavLink, useFetcher} from "react-router-dom";
import {
    home_dashboard_phrasal_verb_list_page_route,
    homeAuthSignOutRoute,
    homeDashboardVerbsRoute,
    homeRoute
} from "../routes/route-generators";
import {primaryColor} from "../constants/color-constants";

const DashboardLayout = ({children}) => {
    const fetcher = useFetcher();

    const isSubmitting = fetcher.state === 'submitting';

    return (
        <Fragment>
            <div className='bg-white dar:bg-gray-900'>
                <aside
                    className='transition-all ease duration-[0.2s] group fixed left-0 top-0 z-20 h-screen w-[calc(3.73rem)] border-r border-gray-300/40 dark:border-gray-700 bg-white dark:bg-gray-900 hover:w-56 hover:shadow-2xl'>
                    <div className='h-full flex flex-col justify-between'>
                        <div>
                            <div
                                className='mb-6 h-16 flex justify-center items-center border-b border-gray-300/40 dark:border-gray-700'>
                                <Link to={homeRoute()}>
                                    <p className='transition-all ease duration-[0.3s] block w-min text-white text-[2rem]'>v<span
                                        className='hidden group-hover:inline'>erbly</span></p>
                                </Link>
                            </div>
                            <div className='mt-6'>
                                <ul className='px-1 -ml-px space-y-4 font-medium tracking-wide'>
                                    <li className='w-max space-y-4 group-hover:w-full'>
                                        <Link className='block w-[52px] py-3 rounded-full bg-sky-500 group-hover:w-full'
                                              to='/dashboard'>
                                            <div className='w-max flex items-center gap-4 px-4'>
                                                <i className='bx bxs-dashboard text-white text-[20px]'></i>
                                                <span
                                                    className='transition-all ease hidden group-hover:inline dark:text-white'>Dashboard</span>
                                            </div>
                                        </Link>
                                    </li>

                                    <li className='w-max'>
                                        <NavLink className={({isActive}) => {
                                            return isActive ? `text-[${primaryColor}]` : 'text-white';
                                        }} to={homeDashboardVerbsRoute()}>
                                            <div className='flex items-center gap-4 px-4 py-3'>
                                                <i className='bx bxs-pencil text-[20px]'></i>
                                                <span
                                                    className='transition-all ease duration-[0.3s] hidden group-hover:inline'>Verbs</span>
                                            </div>
                                        </NavLink>
                                    </li>

                                    <li className='w-max'>
                                        <NavLink className={({isActive}) => {
                                            return isActive ? `text-[${primaryColor}]` : 'text-white';
                                        }} to={home_dashboard_phrasal_verb_list_page_route()}>
                                            <div className='flex items-center gap-4 px-4 py-3'>
                                                <i className='bx bxs-pencil text-[20px]'></i>
                                                <span
                                                    className='transition-all ease duration-[0.3s] hidden group-hover:inline'>Phrasal Verbs</span>
                                            </div>
                                        </NavLink>
                                    </li>

                                    <li className='w-max'>
                                        <NavLink className={({isActive}) => {
                                            return isActive ? `text-[${primaryColor}]` : 'text-white';
                                        }} to={'/dashboard/sentences'}>
                                            <div className='flex items-center gap-4 px-4 py-3'>
                                                <i className='bx bxs-pencil text-[20px]'></i>
                                                <span
                                                    className='transition-all ease duration-[0.3s] hidden group-hover:inline'>Sentences</span>
                                            </div>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='py-4 px-2 border-t border-gray-300/40 dark:border-gray-700'>
                            <fetcher.Form method='POST'
                                          action={homeAuthSignOutRoute()}>
                                <button
                                    className='inline-block w-[52px] py-3 rounded-full bg-red-500 group-hover:w-full'>
                                    {
                                        isSubmitting ?
                                            (
                                                <i className='bx bx-loader-circle'></i>
                                            ) :
                                            (
                                                <div className='w-max flex items-center gap-4 px-4'>
                                                    <i className='bx bx-log-out-circle text-white text-[20px]'></i>
                                                    <span
                                                        className='transition-all ease hidden group-hover:inline dark:text-white'>Sign out</span>
                                                </div>
                                            )
                                    }
                                </button>
                            </fetcher.Form>
                        </div>
                    </div>
                </aside>
                <main className='relative w-[calc(100%-3.73rem)] ml-auto h-screen'>
                    {children}
                </main>
            </div>
        </Fragment>
    );
};

export default DashboardLayout;