import SentenceModel from "../../models/SentenceModel";
import {redirect} from "react-router-dom";
import store from "../../store/store";
import {pageMessageActions} from "../../store/page-message-slice";
import {FAILED_OPERATION} from "../../constants/operation-status-constants";
import {ERROR_MESSAGE, SUCCESS_MESSAGE} from "../../constants/page-message-constants";

export const action = async ({params}) => {
    const sentenceUuid = params.uuid;

    const deleteSentenceResult = await SentenceModel.deleteSentence(sentenceUuid);

    store.dispatch(pageMessageActions.setMessage({
        type: deleteSentenceResult.status === FAILED_OPERATION ? ERROR_MESSAGE : SUCCESS_MESSAGE,
        message: deleteSentenceResult.status === FAILED_OPERATION ? deleteSentenceResult.error : 'Cümle başarıyla silindi.',
        from: 'test',
    }));

    return redirect('..');
}