import {db} from '../config/firebase' ;

import {addDoc, collection, deleteDoc, getDocs, query, updateDoc, where} from "firebase/firestore";
import {FAILED_OPERATION, SUCCESSFUL_OPERATION} from "../constants/operation-status-constants";
import PhrasalVerbData from "../datas/PhrasalVerbData";

class PhrasalVerbModel {
    constructor() {
        if (PhrasalVerbModel.instance == null) {
            PhrasalVerbModel.instance = this;
        }
        return PhrasalVerbModel.instance;
    }

    phrasalVerbsColRef = collection(db, 'phrasal_verbs');

    readPhrasalVerbList = async () => {
        const result = {
            status: FAILED_OPERATION,
            data: null,
            error: null,
        };

        try {
            const docSnapshots = await getDocs(this.phrasalVerbsColRef);

            const data = docSnapshots.docs.map((docSnapshot) => new PhrasalVerbData({
                uuid: docSnapshot.data()['uuid'],
                verbUuid: docSnapshot.data()['verb_uuid'],
                phrasalVerb: docSnapshot.data()['phrasal_verb'],
            }));

            result.data = data;
            result.status = SUCCESSFUL_OPERATION;
        } catch (exception) {
            result.error = exception;
        }

        return result;
    }

    readPhrasalVerb = async (uuid) => {
        const result = {
            status: FAILED_OPERATION,
            data: null,
            error: null,
        }

        try {
            const q = query(this.phrasalVerbsColRef, where('uuid', '==', uuid));

            const querySnapshot = await getDocs(q);

            const document = querySnapshot.docs[0];

            result.data = PhrasalVerbData.fromDocumentSnapshot(document)
            result.status = SUCCESSFUL_OPERATION;
        } catch (exception) {
            result.error = exception.toString();
        }

        return result;
    }

    createPhrasalVerb = async ({uuid, verbUuid, phrasalVerb}) => {
        const result = {
            status: FAILED_OPERATION,
            error: null,
        };

        try {
            await addDoc(this.phrasalVerbsColRef, {
                'uuid': uuid,
                'verb_uuid': verbUuid,
                'phrasal_verb': phrasalVerb,
            });
            result.status = SUCCESSFUL_OPERATION;
        } catch (exception) {
            result.error = exception;
        }

        return result;
    }

    // todo: delete islemi soft delete olmali
    deletePhrasalVerb = async (uuid) => {
        const result = {
            status: FAILED_OPERATION,
            error: null,
        }

        try {
            const q = query(this.phrasalVerbsColRef, where('uuid', '==', uuid));

            const querySnapshot = await getDocs(q);

            const document = querySnapshot.docs[0];

            await deleteDoc(document.ref);

            result.status = SUCCESSFUL_OPERATION;
        } catch (exception) {
            result.error = exception.toString();
        }

        return result;
    }

    updatePhrasalVerb = async (uuid, {phrasalVerb}) => {
        const result = {
            status: FAILED_OPERATION,
            error: null,
        }

        try {

            const q = query(this.phrasalVerbsColRef, where('uuid', '==', uuid));

            const querySnapshot = await getDocs(q);

            const docRef = querySnapshot.docs[0].ref;

            await updateDoc(docRef, {'phrasal_verb': phrasalVerb});
            result.status = SUCCESSFUL_OPERATION;
        } catch (exception) {
            result.error = exception.toString();
        }

        return result;
    }
}

const instance = new PhrasalVerbModel();
Object.freeze(instance);
export default instance;