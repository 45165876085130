// todo: butun route'lari fonksiyon haline getir

export const homeRoute = () => {
    return '/';
};

export const homeAuthSignInRoute = () => {
    return '/auth/sign-in';
}

export const homeAuthSignOutRoute = () => {
    return '/auth/sign-out';
}

export const homeDashboardRoute = () => {
    return '/dashboard';
}

export const homeDashboardVerbsRoute = () => {
    return '/dashboard/verbs';
}

export const homeDashboardVerbsCreateRoute = () => {
    return '/dashboard/verbs';
}

export const homeDashboardVerbsUuidDeleteRoute = (uuid) => {
    return `/dashboard/verbs/${uuid}/delete`;
}

export const homeDashboardVerbsUuidUpdateRoute = (uuid) => {
    return `/dashboard/verbs/${uuid}/update`;
}

export const home_dashboard_phrasal_verb_list_page_route = () => {
    return '/dashboard/phrasal-verbs';
};

export const home_dashboard_phrasal_verb_create_page_route = () => {
    return '/dashboard/phrasal-verbs/create';
};


