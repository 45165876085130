import {useDispatch, useSelector} from "react-redux";
import React, {Fragment, useEffect} from "react";
import {ERROR_MESSAGE, INFO_MESSAGE, SUCCESS_MESSAGE} from "../constants/page-message-constants";
import {toast, ToastContainer} from "react-toastify";
import {pageMessageActions} from "../store/page-message-slice";

const PageMessageWrapper = ({children}) => {
    const pageMessage = useSelector(state => state.pageMessage);
    const dispatch = useDispatch();


    useEffect(() => {
        if (pageMessage.message !== null) {
            if (pageMessage.type === SUCCESS_MESSAGE) {
                toast.success(pageMessage.message);
            } else if (pageMessage.type === ERROR_MESSAGE) {
                toast.error(pageMessage.message);
            } else if (pageMessage.type === INFO_MESSAGE) {
                toast.info(pageMessage.message);
            }
            dispatch(pageMessageActions.resetMessage());
        }
    }, [pageMessage, dispatch],);

    return (
        <Fragment>
            <ToastContainer/>
            {children}
        </Fragment>
    );
};

export default PageMessageWrapper;