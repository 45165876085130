import {db} from '../config/firebase' ;

import {collection, getDocs, query, where} from "firebase/firestore";
import {HAS_VALUE} from "../constants/status-constants";
import {FAILED_OPERATION, SUCCESSFUL_OPERATION} from "../constants/operation-status-constants";
import VerbData from "../datas/VerbData";

class VerbModel {
    constructor() {
        if (VerbModel.instance == null) {
            VerbModel.instance = this;
        }
        return VerbModel.instance;
    }

    verbsColRef = collection(db, 'verbs');

    // todo: firestore'daki verb islemleri remote-storage.js dosyasindan buraya tasinmali
    // todo: delete islemi soft delete olmali

    getVerbList = async () => {
        const result = {
            status: FAILED_OPERATION,
            data: null,
            error: null,
        };

        try {
            const docSnapshots = await getDocs(this.verbsColRef);

            const data = docSnapshots.docs.map((docSnapshot) => new VerbData(docSnapshot.data()));

            result.data = data;
            result.status = SUCCESSFUL_OPERATION;
        } catch (exception) {
            result.error = exception;
        }

        return result;
    }

    getVerb = async (uuid) => {
        const result = {
            status: FAILED_OPERATION,
            data: null,
            error: null,
        }

        try {

            const q = query(this.verbsColRef, where('uuid', '==', uuid));

            const querySnapshot = await getDocs(q);

            const document = querySnapshot.docs[0];

            result.data = new VerbData({
                uuid: document.data()['uuid'],
                verb: document.data()['verb'],
            });
            result.status = SUCCESSFUL_OPERATION;
        } catch (exception) {
            result.error = exception.toString();
        }

        return result;
    }

}

const instance = new VerbModel();
Object.freeze(instance);
export default instance;