import React, {
    Fragment
} from "react";
import DashboardPageLayout from "../layouts/DashboardPageLayout";

const DashboardHomePage = () => {
    return (
        <Fragment>
            <DashboardPageLayout title={'Dashboard'}>

            </DashboardPageLayout>
        </Fragment>
    );
};

export default DashboardHomePage;