import React, {
    Fragment, useEffect,
    useState,
} from "react";

import image1 from '../assets/images/mockup-1.png';
import image2 from '../assets/images/mockup-2.png';
import image3 from '../assets/images/mockup-3.png';
import image4 from '../assets/images/mockup-4.png';
import image5 from '../assets/images/mockup-5.png';

const Carousel = () => {

    const images = [
        {image: image1, key: 'a'},
        {image: image2, key: 'b'},
        {image: image3, key: 'c'},
        {image: image4, key: 'd'},
        {image: image5, key: 'e'},
    ];

    const [currentImage, setCurrentImage] = useState(0);

    const previousButtonHandler = () => {
        setCurrentImage((prev) => {
            return (prev === 0) ? images.length - 1 : prev - 1;
        });
    }
    const nextButtonHandler = () => {
        setCurrentImage((prev) => {
            return (prev === images.length - 1) ? 0 : prev + 1;
        });
    }

    useEffect(() => {
        const slideInterval = setInterval(nextButtonHandler, 3000);
        return () => clearInterval(slideInterval);
    }, []);

    return (
        <Fragment>
            <div className='flex flex-row gap-x-5'>
                <div className='flex flex-col justify-center px-2'>
                    <button className='transition-colors ease bg-white hover:bg-gray-200 shadow p-2 rounded-full'
                            onClick={previousButtonHandler}>
                        <i className='bx bx-chevron-left text-[40px]'></i>
                    </button>
                </div>
                <div className='overflow-hidden'>
                    <div className='flex md:w-[300px] transition-transform ease-out duration-500'
                         style={{transform: `translateX(-${currentImage * 100}%)`}}>
                        {
                            images.map((image) => (
                                <img key={image.key}
                                     src={image.image}/>
                            ))
                        }
                    </div>
                </div>
                <div className='flex flex-col justify-center px-2'>
                    <button className='transition-colors ease bg-white hover:bg-gray-200 shadow p-2 rounded-full'
                            onClick={nextButtonHandler}>
                        <i className='bx bx-chevron-right text-[40px]'></i>
                    </button>
                </div>
            </div>
        </Fragment>
    );
};

export default Carousel;