import React, {Fragment} from 'react';
import DashboardPageLayout from "../layouts/DashboardPageLayout";
import {Form, Link, useActionData, useLoaderData, useNavigation} from "react-router-dom";
import {home_dashboard_phrasal_verb_list_page_route} from "../routes/route-generators";

const SentenceUpdatePage = () => {
    const loaderData = useLoaderData();

    const actionData = useActionData();
    const navigation = useNavigation();


    const isSubmitting = navigation.state === 'submitting';

    return (
        <Fragment>
            <DashboardPageLayout title='User Create'>
                <div className='px-40 py-5'>
                    <Form className='flex flex-col gap-y-5'
                          method='POST'>
                        <div className='flex flex-row justify-between items-center'>
                            <Link
                                className='shadow-md uppercase inline-block px-6 py-2.5 font-medium tracking-wide text-white transition-colors duration-300 transform bg-red-600 rounded-lg hover:bg-red-500 focus:outline-none focus:ring focus:ring-red-300 focus:ring-opacity-80'
                                to={home_dashboard_phrasal_verb_list_page_route()}>
                                back
                            </Link>
                            <button
                                className="shadow-md px-6 py-2.5 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80"
                                type='submit'>
                                <div className='flex flex-row justify-center items-center gap-x-2'>
                                <span
                                    className='text-md font-medium uppercase'>{isSubmitting ? (
                                    <i className='bx bx-loader-circle bx-spin'></i>) : 'update'}</span>
                                </div>
                            </button>
                        </div>

                        <div>
                            <label htmlFor="uuid"
                                   className="block text-sm text-gray-500 dark:text-gray-300">UUID</label>
                            <input name='uuid'
                                   id='uuid'
                                   type="text"
                                   placeholder="Generate new uuid..."
                                   value={loaderData.sentenceData.uuid}
                                   className="block mt-2 w-full placeholder-gray-400/70 dark:placeholder-gray-500 rounded-lg border border-gray-200 pe-36 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-blue-300"
                                   readOnly={true}/>
                        </div>

                        <div className='w-full'>
                            <label className="block text-sm text-gray-500 dark:text-gray-300"
                                   htmlFor="phrasalVerb">Phrasal Verb</label>
                            <div className='block'>
                                <select
                                    className="mt-2 px-6 py-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    name='phrasalVerb'
                                    id='phrasalVerb'>
                                    <option key={loaderData.phrasalVerbData.uuid}
                                            value={loaderData.phrasalVerbData.uuid}>{
                                        loaderData.phrasalVerbData.phrasalVerb
                                    }</option>
                                </select>
                            </div>
                        </div>

                        <div className='w-full'>
                            <label htmlFor="en"
                                   className="block text-sm text-gray-500 dark:text-gray-300">English Sentence</label>
                            <textarea name='en'
                                      id='en'
                                      placeholder="English sentence here..."
                                      rows="3"
                                      defaultValue={loaderData.sentenceData.en}
                                      className="block mt-2 w-full placeholder-gray-400/70 dark:placeholder-gray-500 rounded-lg border border-gray-200 pe-36 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-blue-300 resize-none"/>
                            {actionData?.validation?.errors?.en && (
                                <i className='mt-1 ms-3 text-[10px] text-red-500'>{actionData.validation.errors.en}</i>
                            )}
                        </div>

                        <div className='w-full'>
                            <label htmlFor="tr"
                                   className="block text-sm text-gray-500 dark:text-gray-300">Turkish Sentence</label>
                            <textarea name='tr'
                                      id='tr'
                                      placeholder="Turkish sentence here..."
                                      rows="3"
                                      defaultValue={loaderData.sentenceData.tr}
                                      className="block mt-2 w-full placeholder-gray-400/70 dark:placeholder-gray-500 rounded-lg border border-gray-200 pe-36 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-blue-300 resize-none"/>
                            {actionData?.validation?.errors?.tr && (
                                <i className='mt-1 ms-3 text-[10px] text-red-500'>{actionData.validation.errors.tr}</i>
                            )}
                        </div>
                    </Form>
                </div>
            </DashboardPageLayout>
        </Fragment>
    );
};

export default SentenceUpdatePage;