import PhrasalVerbModel from "../../models/PhrasalVerbModel";
import {FAILED_OPERATION} from "../../constants/operation-status-constants";
import store from "../../store/store";
import {pageMessageActions} from "../../store/page-message-slice";
import {ERROR_MESSAGE} from "../../constants/page-message-constants";
import {json} from "react-router-dom";

export const loader = async () => {

    const result = await PhrasalVerbModel.readPhrasalVerbList();

    if (result.status === FAILED_OPERATION) {
        store.dispatch(pageMessageActions.setMessage({
            type: ERROR_MESSAGE,
            message: result.error.toString(),
            from: 'test',
        }));
    }

    return json({
        phrasalVerbList: result.data,
    });
}