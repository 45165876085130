import {
    createSlice
} from '@reduxjs/toolkit';

const initialState = {
    userId: null,
    isAuthenticated: false,
};

const authenticationSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        signIn: (state, action) => {
            state.userId = action.payload.userId;
            state.isAuthenticated = true;
        },
        signOut: (state) => {
            state.userId = null;
            state.isAuthenticated = false;
        },
    }
});

export const authenticationActions = authenticationSlice.actions;

export const authenticationReducer = authenticationSlice.reducer;