import {
    signInWithEmailAndPassword,
    signOut,
} from 'firebase/auth';

import {
    auth
} from "../config/firebase";

export const signIn = async ({email, password}) => {
    const data = {
        error: null,
        userId: null,
        isAuthenticated: false,
    }
    try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);

        data.userId = userCredential.user.uid;
        data.isAuthenticated = true;
        localStorage.setItem('_', data.userId);

    } catch (exception) {
        if (exception.code === 'auth/user-not-found') {
            data.error = 'Kullanıcı bulunamadı';
        } else if (exception.code === 'auth/invalid-email') {
            data.error = 'Geçersiz e-post adresi';
        } else if (exception.code === 'auth/wrong-password') {
            data.error = 'Hatalı parola';
        } else if (exception.code === 'auth/too-many-requests') {
            data.error = 'Çok fazla giriş denediniz';
        }
        console.log(exception);
    }

    return data;
}

export const isSignedIn = () => {
    const authData = {
        isAuthenticated: false,
        userId: null,
    }

    if (localStorage.getItem('_') !== null) {
        authData.isAuthenticated = true;
        authData.userId = localStorage.getItem('_');
    }

    return authData;
}


export const signout = async () => {
    const data = {
        error: null,
    }
    try {
        await signOut(auth);

        localStorage.removeItem('_');

    } catch (exception) {
        console.log(exception);
    }

    return data;
}
