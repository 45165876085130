import React, {Fragment, useEffect, useState} from 'react';
import DashboardPageLayout from "../layouts/DashboardPageLayout";
import {Form, Link, useActionData, useLoaderData, useNavigation, useSubmit} from "react-router-dom";
import {home_dashboard_phrasal_verb_list_page_route, homeDashboardVerbsRoute} from "../routes/route-generators";
import {v4 as uuidv4} from 'uuid';

const PhrasalVerbCreatePage = () => {
    const submit = useSubmit();
    const loaderData = useLoaderData();

    const actionData = useActionData();
    const navigation = useNavigation();

    const [uuid, setUUID] = useState('');

    const isSubmitting = navigation.state === 'submitting';

    useEffect(() => {
        const initialUUID = uuidv4();
        setUUID(initialUUID);
    }, []);

    const uuidGenerateButtonHandler = () => {
        const generatedUUID = uuidv4();
        setUUID(generatedUUID);
    }


    const formSubmitHandler = (event) => {
        event.preventDefault();

        const submittedUuid = event.target.elements[1].value;
        const submittedVerbUuid = event.target.elements[3].value;
        const preposition = event.target.elements[4].value;

        const requiredVerb = loaderData.verbList.filter((verbData) => verbData.uuid === submittedVerbUuid)[0].verb;

        submit({
            uuid: submittedUuid,
            verbUuid: submittedVerbUuid,
            verb: requiredVerb,
            preposition: preposition,
        }, {method: 'post'});
    }


    return (
        <Fragment>
            <DashboardPageLayout title='User Create'>
                <div className='px-40 py-5'>
                    <form onSubmit={formSubmitHandler}
                          className='flex flex-col gap-y-5'>
                        <div className='flex flex-row justify-between items-center'>
                            <Link
                                className='shadow-md uppercase inline-block px-6 py-2.5 font-medium tracking-wide text-white transition-colors duration-300 transform bg-red-600 rounded-lg hover:bg-red-500 focus:outline-none focus:ring focus:ring-red-300 focus:ring-opacity-80'
                                to={home_dashboard_phrasal_verb_list_page_route()}>
                                back
                            </Link>
                            <button
                                className="shadow-md px-6 py-2.5 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80"
                                type='submit'>
                                <div className='flex flex-row justify-center items-center gap-x-2'>
                                <span
                                    className='text-md font-medium uppercase'>{isSubmitting ? (
                                    <i className='bx bx-loader-circle bx-spin'></i>) : 'add'}</span>
                                    <i className='bx bx-plus text-[18px]'></i>
                                </div>
                            </button>
                        </div>

                        <div>
                            <label htmlFor="uuid"
                                   className="block text-sm text-gray-500 dark:text-gray-300">UUID</label>
                            <div className='relative'>
                                <input name='uuid'
                                       id='uuid'
                                       type="text"
                                       placeholder="Generate new uuid..."
                                       value={uuid}
                                       className="block mt-2 w-full placeholder-gray-400/70 dark:placeholder-gray-500 rounded-lg border border-gray-200 pe-36 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-blue-300"
                                       readOnly={true}/>
                                <button
                                    className='absolute right-1 top-1/2 -translate-y-[50%] bg-blue-500 my-auto py-2 font-bold text-sm px-3 uppercase rounded-lg text-white'
                                    type='button'
                                    onClick={uuidGenerateButtonHandler}>
                                    Generate
                                </button>
                            </div>
                        </div>

                        <div className='flex flex-row gap-x-5'>
                            <div>
                                <label className="block text-sm text-gray-500 dark:text-gray-300"
                                       htmlFor="verb">Verb</label>
                                <div className='block'>
                                    <select
                                        className="mt-2 px-6 py-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        name='verb'>
                                        {
                                            loaderData.verbList.map((verbData) => {
                                                return (<option key={verbData.uuid}
                                                                value={verbData.uuid}>{verbData.verb}</option>);
                                            })
                                        }
                                    </select>
                                </div>
                            </div>

                            <div className='w-full'>
                                <label htmlFor="preposition"
                                       className="block text-sm text-gray-500 dark:text-gray-300">Preposition</label>
                                <input name='preposition'
                                       id='preposition'
                                       type="text"
                                       placeholder="Preposition here..."
                                       className="block mt-2 w-full placeholder-gray-400/70 dark:placeholder-gray-500 rounded-lg border border-gray-200 pe-36 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-blue-300"/>
                                {actionData?.validation?.errors?.preposition && (
                                    <i className='mt-1 ms-3 text-[10px] text-red-500'>{actionData.validation.errors.preposition}</i>
                                )}
                            </div>
                        </div>
                    </form>
                </div>
            </DashboardPageLayout>
        </Fragment>
    );
};

export default PhrasalVerbCreatePage;