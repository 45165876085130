import {createBrowserRouter} from "react-router-dom";
import MainRoot from "../roots/MainRoot";
import HomePage from "../pages/HomePage";
import ErrorPage from "../pages/ErrorPage";
import AuthRoot from "../roots/AuthRoot";
import SignInPage from "../pages/SignInPage";
import {
    action as homeAuthSignInAction,
    loader as homeAuthSignInLoader,
} from '../routes/route-utilities/home-auth-sign-in-utilities';
import {loader as homeDashboardLoader} from '../routes/route-utilities/home-dashboard-utilities';
import DashboardRoot from "../roots/DashboardRoot";
import DashboardHomePage from "../pages/DashboardHomePage";
import {loader as homeLoader} from '../routes/route-utilities/home-utilities';
import {action as homeAuthSignOutAction} from '../routes/route-utilities/home-auth-sign-out-utilities';
import VerbRoot from "../roots/VerbRoot";
import VerbListPage from "../pages/VerbListPage";
import {loader as homeDashboardVerbsLoader} from '../routes/route-utilities/home-dashboard-verbs-utilities';
import VerbCreatePage from "../pages/VerbCreatePage";
import {action as homeDashboardVerbsCreateAction} from './route-utilities/home-dashboard-verbs-create-utilities';
import {
    action as homeDashboardVerbsUuidDeleteAction
} from './route-utilities/home-dashboard-verbs-uuid-delete-utilities';
import VerbUpdatePage from "../pages/VerbUpdatePage";
import {
    loader as homeDashboardVerbsUuidUpdateLoader,
    action as homeDashboardVerbsUuidUpdateAction,
} from './route-utilities/home-dashboard-verbs-uuid-update-utilities';
import PhrasalVerbRoot from "../roots/PhrasalVerbRoot";
import PhrasalVerbListPage from "../pages/PhrasalVerbListPage";
import {loader as phrasalVerbListPageLoader} from './route-utilities/phrasal-verb-list-page-utilities';
import PhrasalVerbCreatePage from "../pages/PhrasalVerbCreatePage";
import {
    action as phrasalVerbCreatePageAction,
    loader as phrasalVerbCreatePageLoader,
} from './route-utilities/phrasal-verb-create-page-utilities';
import {action as phrasalVerbDeleteRouteAction} from './route-utilities/phrasal-verb-delete-route-utilities';
import PhrasalVerbUpdatePage from "../pages/PhrasalVerbUpdatePage";
import {
    action as phrasalVerbUpdatePageAction,
    loader as phrasalVerbUpdatePageLoader,
} from './route-utilities/phrasal-verb-update-page-utilities';
import SentenceRouteRoot from "../roots/SentenceRouteRoot";
import SentenceListPage from "../pages/SentenceListPage";
import {loader as sentenceListPageLoader} from './route-utilities/sentence-list-page-utilities';
import SentenceCreatePage from "../pages/SentenceCreatePage";
import {
    loader as sentenceCreatePageLoader,
    action as sentenceCreatePageAction
} from './route-utilities/sentence-create-page-utilities';
import {action as sentenceDeleteRouteAction} from './route-utilities/sentence-delete-route-utilities';
import SentenceUpdatePage from "../pages/SentenceUpdatePage";

import {
    loader as sentenceUpdatePageLoader,
    action as sentenceUpdatePageAction,
} from './route-utilities/sentence-update-page-utilities';

const routes = createBrowserRouter([
        {
            path: '/',

            element: <MainRoot/>,
            errorElement: <ErrorPage/>,
            children: [
                {
                    index: true,
                    element: <HomePage/>,
                    loader: homeLoader,
                },
                {
                    path: 'auth',
                    element: <AuthRoot/>,
                    children: [
                        {
                            path: 'sign-in',
                            element: <SignInPage/>,
                            action: homeAuthSignInAction,
                            loader: homeAuthSignInLoader,
                        },
                        {
                            path: 'sign-out',
                            action: homeAuthSignOutAction,
                        }
                    ]
                },
                {
                    path: 'dashboard',
                    element: <DashboardRoot/>,
                    loader: homeDashboardLoader,
                    children: [
                        {
                            index: true,
                            element: <DashboardHomePage/>,
                        },
                        {
                            path: 'verbs',
                            element: <VerbRoot/>,
                            children: [
                                {
                                    index: true,
                                    element: <VerbListPage/>,
                                    loader: homeDashboardVerbsLoader,
                                },
                                {
                                    path: 'create',
                                    element: <VerbCreatePage/>,
                                    action: homeDashboardVerbsCreateAction,
                                },
                                {
                                    path: ':uuid/delete',
                                    action: homeDashboardVerbsUuidDeleteAction,
                                },
                                {
                                    path: ':uuid/update',
                                    element: <VerbUpdatePage/>,
                                    loader: homeDashboardVerbsUuidUpdateLoader,
                                    action: homeDashboardVerbsUuidUpdateAction,
                                }
                            ]
                        },
                        {
                            path: 'phrasal-verbs',
                            element: <PhrasalVerbRoot/>,
                            children: [
                                {
                                    index: true,
                                    element: <PhrasalVerbListPage/>,
                                    loader: phrasalVerbListPageLoader,
                                },
                                {
                                    path: 'create',
                                    element: <PhrasalVerbCreatePage/>,
                                    loader: phrasalVerbCreatePageLoader,
                                    action: phrasalVerbCreatePageAction,
                                },
                                {
                                    path: ':uuid/delete',
                                    action: phrasalVerbDeleteRouteAction,
                                },
                                {
                                    path: ':uuid/update',
                                    element: <PhrasalVerbUpdatePage/>,
                                    loader: phrasalVerbUpdatePageLoader,
                                    action: phrasalVerbUpdatePageAction,
                                }
                            ]
                        },
                        {
                            path: 'sentences',
                            element: <SentenceRouteRoot/>,
                            children: [
                                {
                                    index: true,
                                    element: <SentenceListPage/>,
                                    loader: sentenceListPageLoader,
                                },
                                {
                                    path: 'create',
                                    element: <SentenceCreatePage/>,
                                    loader: sentenceCreatePageLoader,
                                    action: sentenceCreatePageAction,
                                },
                                {
                                    path: ':uuid/delete',
                                    action: sentenceDeleteRouteAction,
                                },
                                {
                                    path: ':uuid/update',
                                    element: <SentenceUpdatePage/>,
                                    loader: sentenceUpdatePageLoader,
                                    action: sentenceUpdatePageAction,
                                }
                            ]
                        }
                    ]
                }
            ],
        }
    ])
;

export default routes;