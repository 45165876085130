import PhrasalVerbModel from "../../models/PhrasalVerbModel";
import {FAILED_OPERATION} from "../../constants/operation-status-constants";
import store from "../../store/store";
import {pageMessageActions} from "../../store/page-message-slice";
import {ERROR_MESSAGE, SUCCESS_MESSAGE} from "../../constants/page-message-constants";
import {homeDashboardVerbsUuidDeleteRoute} from "../route-generators";

export const action = async ({params}) => {
    const uuid = params.uuid;

    const result = await PhrasalVerbModel.deletePhrasalVerb(uuid);

    console.log(result);

    store.dispatch(pageMessageActions.setMessage({
        message: result.status === FAILED_OPERATION ? result.error : 'Phrasal Verb basariyla silindi',
        type: result.status === FAILED_OPERATION ? ERROR_MESSAGE : SUCCESS_MESSAGE,
        from: '/test/',
    }));

    return null;
}