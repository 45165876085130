import {db} from '../config/firebase' ;

import {addDoc, collection, deleteDoc, getDocs, query, updateDoc, where} from "firebase/firestore";
import {FAILED_OPERATION, SUCCESSFUL_OPERATION} from "../constants/operation-status-constants";
import SentenceData from "../datas/SentenceData";

class SentenceModel {
    constructor() {
        if (SentenceModel.instance == null) {
            SentenceModel.instance = this;
        }
        return SentenceModel.instance;
    }

    sentencesColRef = collection(db, 'sentences');

    readSentenceList = async () => {
        const result = {
            status: FAILED_OPERATION,
            data: null,
            error: null,
        };

        try {
            const docSnapshots = await getDocs(this.sentencesColRef);

            const sentenceDataList = docSnapshots.docs.map((docSnapshot) => SentenceData.fromDocumentSnapshot(docSnapshot));

            result.data = sentenceDataList;
            result.status = SUCCESSFUL_OPERATION;
        } catch (exception) {
            result.error = exception;
        }

        return result;
    }

    readSentence = async (uuid) => {
        const result = {
            status: FAILED_OPERATION,
            data: null,
            error: null,
        }

        try {
            const q = query(this.sentencesColRef, where('uuid', '==', uuid));

            const querySnapshot = await getDocs(q);

            const document = querySnapshot.docs[0];

            result.data = SentenceData.fromDocumentSnapshot(document);
            result.status = SUCCESSFUL_OPERATION;
        } catch (exception) {
            result.error = exception.toString();
        }

        return result;
    }

    createSentence = async ({uuid, phrasalVerbUuid, en, tr}) => {
        const result = {
            status: FAILED_OPERATION,
            error: null,
        };

        try {
            await addDoc(this.sentencesColRef, {
                'uuid': uuid,
                'phrasal_verb_uuid': phrasalVerbUuid,
                'en': en,
                'tr': tr,
            });
            result.status = SUCCESSFUL_OPERATION;
        } catch (exception) {
            result.error = exception.toString();
        }
        return result;
    }

    // todo: delete islemi soft delete olmali
    deleteSentence = async (uuid) => {
        const result = {
            status: FAILED_OPERATION,
            error: null,
        }

        try {
            const q = query(this.sentencesColRef, where('uuid', '==', uuid));

            const querySnapshot = await getDocs(q);

            if (querySnapshot.docs.length === 0) throw new Error('Cümle bulunamadı!');

            const document = querySnapshot.docs[0];

            await deleteDoc(document.ref);

            result.status = SUCCESSFUL_OPERATION;
        } catch (exception) {
            result.error = exception.toString();
        }

        return result;
    }

    updateSentence = async (uuid, {en, tr}) => {
        const result = {
            status: FAILED_OPERATION,
            error: null,
        }

        try {
            const q = query(this.sentencesColRef, where('uuid', '==', uuid));

            const querySnapshot = await getDocs(q);

            const docRef = querySnapshot.docs[0].ref;

            await updateDoc(docRef, {
                'en': en,
                'tr': tr,
            });
            result.status = SUCCESSFUL_OPERATION;
        } catch (exception) {
            result.error = exception.toString();
        }

        return result;
    }
}

const instance = new SentenceModel();
Object.freeze(instance);
export default instance;