import SentenceModel from "../../models/SentenceModel";
import {SUCCESSFUL_OPERATION} from "../../constants/operation-status-constants";
import {json} from "react-router-dom";

export const loader = async () => {
    const sentenceListRequest = await SentenceModel.readSentenceList();

    if (sentenceListRequest.status === SUCCESSFUL_OPERATION) {
        return json({
            sentenceDataList: sentenceListRequest.data,
        });
    }
}
