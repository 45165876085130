import {
    initializeApp
} from "firebase/app";

import {
    getAuth
} from 'firebase/auth';

import {
    getFirestore
} from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyARbeWYjAwkmLz2s3RbDKWSXv9T3PE0-pY",
    authDomain: "verbly-app-test.firebaseapp.com",
    projectId: "verbly-app-test",
    storageBucket: "verbly-app-test.appspot.com",
    messagingSenderId: "979663668436",
    appId: "1:979663668436:web:a19a85674e43220edbe741"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export const db = getFirestore(app);