import React, {Fragment} from "react";

const DashboardPageLayout = ({children, title}) => {
    return (
        <Fragment>
            <div className='w-full h-full'>
                <div
                    className='sticky z-10 bg-white top-0 left-0 right-0 flex items-center h-16 border-b border-gray-300'>
                    <h3 className='text-3xl font-medium text-gray-700 translate-x-4 inline uppercase'>{title ?? 'Dashboard'}</h3>
                </div>
                <div className='w-full h-[calc(100%-64px)]'>
                    {children}
                </div>
            </div>
        </Fragment>
    );
};

export default DashboardPageLayout;