import {
    Outlet
} from "react-router-dom";

const VerbRoot = () => {
    return (
        <Outlet/>
    );
};

export default VerbRoot;