import {verbCreateFormValidation} from "../../services/validation/validations";
import {FORM_INVALID} from "../../constants/validation-constants";
import {json, redirect} from "react-router-dom";
import {addVerb} from "../../services/remote-storage";
import store from "../../store/store";
import {pageMessageActions} from "../../store/page-message-slice";
import {FAILED_OPERATION} from "../../constants/operation-status-constants";
import {ERROR_MESSAGE, SUCCESS_MESSAGE} from "../../constants/page-message-constants";
import {homeDashboardVerbsCreateRoute, homeDashboardVerbsUuidDeleteRoute} from "../route-generators";

export const action = async ({request}) => {
    const requestData = await request.formData();
    const formData = {
        uuid: requestData.get('uuid'),
        verb: requestData.get('verb'),
    };

    const validation = verbCreateFormValidation(formData);

    if (validation.status === FORM_INVALID) {
        return json({
            validation: validation,
        });
    }

    const result = await addVerb(formData);

    store.dispatch(pageMessageActions.setMessage({
        message: result.status === FAILED_OPERATION ? result.error : `${formData.verb} basariyla eklendi`,
        type: result.status === FAILED_OPERATION ? ERROR_MESSAGE : SUCCESS_MESSAGE,
        from: homeDashboardVerbsCreateRoute(),
    }))

    return redirect('..');
}