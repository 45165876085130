import {json} from "react-router-dom";
import {getVerbList} from "../../services/remote-storage";
import {NO_VALUE} from "../../constants/status-constants";

export const loader = async () => {
    const pageData = {};

    const response = await getVerbList();

    if (response.status === NO_VALUE) {
        pageData.verbList = [];
    }

    pageData.verbList = response.data;


    return json(pageData);
}