import React, {
    Fragment
} from "react";
import {
    RouterProvider
} from "react-router-dom";
import routes from "./routes/routes";

const App = () => {
    return (
        <Fragment>
            <RouterProvider router={routes}/>
        </Fragment>
    );
}

export default App;
