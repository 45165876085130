import PhrasalVerbModel from "../../models/PhrasalVerbModel";
import {FAILED_OPERATION} from "../../constants/operation-status-constants";
import store from "../../store/store";
import {pageMessageActions} from "../../store/page-message-slice";
import {ERROR_MESSAGE, SUCCESS_MESSAGE} from "../../constants/page-message-constants";
import {json, redirect} from "react-router-dom";
import {sentenceCreateFormValidation} from "../../services/validation/validations";
import {FORM_INVALID} from "../../constants/validation-constants";
import SentenceModel from "../../models/SentenceModel";

export const loader = async () => {
    const phrasalVerbListRequest = await PhrasalVerbModel.readPhrasalVerbList();
    if (phrasalVerbListRequest.status === FAILED_OPERATION) {
        store.dispatch(pageMessageActions.setMessage({
            type: ERROR_MESSAGE,
            message: phrasalVerbListRequest.error,
            from: 'test',
        }));
        return redirect('..');
    }

    return json({
        phrasalVerbDataList: phrasalVerbListRequest.data,
    });
}

export const action = async ({request}) => {
    const requestData = await request.formData();
    const formData = {
        uuid: requestData.get('uuid'),
        phrasalVerbUuid: requestData.get('phrasalVerb'),
        en: requestData.get('en'),
        tr: requestData.get('tr'),
    };

    const validation = sentenceCreateFormValidation(formData);
    if (validation.status === FORM_INVALID) {
        return json({
            validation: validation,
        });
    }

    const createSentenceResult = await SentenceModel.createSentence(formData);

    store.dispatch(pageMessageActions.setMessage({
        type: createSentenceResult.status === FAILED_OPERATION ? ERROR_MESSAGE : SUCCESS_MESSAGE,
        message: createSentenceResult.status === FAILED_OPERATION ? createSentenceResult.error : 'Cümle başarıyla eklendi',
        from: 'test',
    }));
    return redirect('..');
}