import {FORM_INVALID, FORM_VALID} from "../../constants/validation-constants";
import {rule1} from "./validation-rules";
import {rule1Error} from "./validation-errors";

const emailRegExp = new RegExp('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$');

export const signInFormValidation = ({email, password}) => {
    const validation = {
        status: FORM_VALID,
        errors: {
            email: null,
            password: null,
        },
    };

    if (!email) {
        validation.status = FORM_INVALID;
        validation.errors.email = 'E-posta alanı boş bırakılamaz';
    } else if (!emailRegExp.test(email)) {
        validation.status = FORM_INVALID;
        validation.errors.email = 'Geçerli bir e-posta adresi girmelisiniz';
    }

    if (!password) {
        validation.status = FORM_INVALID;
        validation.errors.password = 'Parola alanı boş bırakılamaz';
    }

    return validation;
}

export const verbCreateFormValidation = ({uuid, verb}) => {
    const validation = {
        status: FORM_VALID,
        errors: {
            uuid: null,
            verb: null,
        },
    };

    if (rule1(uuid)) {
        validation.status = FORM_INVALID;
        validation.errors.uuid = rule1Error('uuid');
    }

    if (rule1(verb)) {
        validation.status = FORM_INVALID;
        validation.errors.verb = rule1Error('verb');
    }

    return validation;
}

export const verbUpdateFormValidation = ({verb}) => {
    const validation = {
        status: FORM_VALID,
        errors: {
            verb: null,
        }
    }

    if (rule1(verb)) {
        validation.status = FORM_INVALID;
        validation.errors.verb = rule1Error('verb');
    }

    return validation;
}

export const phrasalVerbCreateFormValidation = ({preposition}) => {
    const validation = {
        status: FORM_VALID,
        errors: {
            preposition: null,
        }
    }

    if (rule1(preposition)) {
        validation.status = FORM_INVALID;
        validation.errors.preposition = rule1Error('preposition');
    }

    return validation;
}

export const phrasalVerbUpdateFormValidation = ({preposition}) => {
    const validation = {
        status: FORM_VALID,
        errors: {
            preposition: null,
        }
    }

    if (rule1(preposition)) {
        validation.status = FORM_INVALID;
        validation.errors.preposition = rule1Error('preposition');
    }

    return validation;
}

export const sentenceCreateFormValidation = ({en, tr}) => {
    const validation = {
        status: FORM_INVALID,
        errors: {
            en: null,
            tr: null,
        }
    }

    if (rule1(en)) {
        validation.errors.en = rule1Error('en');
    }

    if (rule1(tr)) {
        validation.errors.tr = rule1Error('tr');
    }

    const hasError = !Object.values(validation.errors).every(element => element === null);
    if (!hasError) validation.status = FORM_VALID;

    return validation;
}

export const sentenceUpdateFormValidation = ({en, tr}) => {
    const validation = {
        status: FORM_INVALID,
        errors: {
            en: null,
            tr: null,
        }
    }

    if (rule1(en)) {
        validation.errors.en = rule1Error('en');
    }

    if (rule1(tr)) {
        validation.errors.tr = rule1Error('tr');
    }

    const hasError = !Object.values(validation.errors).every(element => element === null);
    if (!hasError) validation.status = FORM_VALID;

    return validation;
}

