import React, {Fragment} from 'react';
import DashboardPageLayout from "../layouts/DashboardPageLayout";
import PageMessageWrapper from "../wrappers/PageMessageWrapper";
import {Link, useLoaderData} from "react-router-dom";
import SentenceList from "../components/SentenceList";

const SentenceListPage = () => {
    const loaderData = useLoaderData();

    return (
        <DashboardPageLayout title='Sentences'>
            <PageMessageWrapper>
                <div className='w-full h-full flex flex-col px-40 py-5'>
                    <div className='flex flex-row justify-end'>
                        <Link
                            className="inline-block px-6 py-2.5 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80"
                            to={'/dashboard/sentences/create'}>
                            <div className='flex flex-row justify-center items-center gap-x-2'>
                                <span className='text-md font-medium uppercase'>add sentence</span>
                                <i className='bx bx-plus text-[18px]'></i>
                            </div>
                        </Link>
                    </div>
                    <div className="inline-block min-w-full py-2 align-middle">
                        <SentenceList sentenceDataList={loaderData.sentenceDataList}/>
                        {
                            (loaderData.sentenceDataList.length === 0) && (
                                <div className='flex flex-row justify-center mt-20'>
                                    <span className='text-[20px] font-bold'>Henüz sentence eklenmemiş</span>
                                </div>
                            )
                        }
                    </div>
                </div>
            </PageMessageWrapper>
        </DashboardPageLayout>
    );
};

export default SentenceListPage;