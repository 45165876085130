import VerbModel from "../../models/VerbModel";
import {FAILED_OPERATION} from "../../constants/operation-status-constants";
import store from "../../store/store";
import {pageMessageActions} from "../../store/page-message-slice";
import {ERROR_MESSAGE, INFO_MESSAGE, SUCCESS_MESSAGE} from "../../constants/page-message-constants";
import {json, redirect} from "react-router-dom";
import PhrasalVerbModel from "../../models/PhrasalVerbModel";
import {phrasalVerbUpdateFormValidation} from "../../services/validation/validations";
import {FORM_INVALID} from "../../constants/validation-constants";

export const action = async ({request, params}) => {
    const requestData = await request.formData();
    const formData = {
        uuid: requestData.get('uuid'),
        verbUuid: requestData.get('verbUuid'),
        verb: requestData.get('verb'),
        preposition: requestData.get('preposition'),
    }

    const validation = phrasalVerbUpdateFormValidation(formData);

    if (validation.status === FORM_INVALID) {
        return json({
            validation: validation,
        });
    }

    const originalVerbResult = await PhrasalVerbModel.readPhrasalVerb(formData.uuid);

    if (originalVerbResult.status === FAILED_OPERATION) {
        store.dispatch(pageMessageActions.setMessage({
            type: ERROR_MESSAGE,
            message: originalVerbResult.error,
            from: 'test',
        }));
        return redirect('..');
    }

    const newPhrasalVerb = `${formData.verb} ${formData.preposition}`;
    if (newPhrasalVerb === originalVerbResult.data.phrasalVerb) {
        store.dispatch(pageMessageActions.setMessage({
            type: INFO_MESSAGE,
            message: 'Herhangi bir değişiklik yapılmadı',
            from: 'test',
        }));
        return redirect('..');
    }

    const phrasalVerbUpdateResult = await PhrasalVerbModel.updatePhrasalVerb(formData.uuid, {phrasalVerb: newPhrasalVerb});

    store.dispatch(pageMessageActions.setMessage({
        type: phrasalVerbUpdateResult.status === FAILED_OPERATION ? ERROR_MESSAGE : SUCCESS_MESSAGE,
        message: phrasalVerbUpdateResult.status === FAILED_OPERATION ? phrasalVerbUpdateResult.error : 'Phrasal verb başarıyla güncellendi.',
        from: 'test',
    }));

    return redirect('..');
}

export const loader = async ({params}) => {
    const phrasalVerbResult = await PhrasalVerbModel.readPhrasalVerb(params.uuid);

    if (phrasalVerbResult.status === FAILED_OPERATION) {
        store.dispatch(pageMessageActions.setMessage({
            type: ERROR_MESSAGE,
            message: phrasalVerbResult.error,
            from: 'test',
        }));
        return redirect('..');
    }

    const verbResult = await VerbModel.getVerb(phrasalVerbResult.data.verbUuid);

    if (verbResult.status === FAILED_OPERATION) {
        store.dispatch(pageMessageActions.setMessage({
            type: ERROR_MESSAGE,
            message: verbResult.error,
            from: 'test',
        }));
        return redirect('..');
    }


    return json({
        phrasalVerb: phrasalVerbResult.data,
        verb: verbResult.data,
    });
}