import {
    db
} from '../config/firebase' ;

import {
    getDocs,
    collection,
    doc,
    addDoc,
    query,
    where,
    deleteDoc,
    updateDoc,
} from 'firebase/firestore';

import {HAS_VALUE, NO_VALUE} from "../constants/status-constants";
import {FAILED_OPERATION, SUCCESSFUL_OPERATION} from "../constants/operation-status-constants";

export const getVerbList = async () => {
    const data = {
        status: NO_VALUE,
    }

    const verbsColRef = collection(db, 'verbs');

    try {
        const docsSnapshot = await getDocs(verbsColRef);

        data.status = HAS_VALUE;
        data.data = docsSnapshot.docs.map((doc) => doc.data());

    } catch (exception) {
        console.log(exception);
    }

    return data;
}

export const addVerb = async ({uuid, verb}) => {
    const data = {
        status: SUCCESSFUL_OPERATION,
        error: null,
    }

    const verbsColRef = collection(db, 'verbs');

    try {

        await addDoc(verbsColRef, {
            uuid,
            verb,
        });

    } catch (exception) {
        console.log(exception);
    }

    return data;
}

export const deleteVerb = async (uuid) => {
    const data = {
        status: SUCCESSFUL_OPERATION,
        error: null,
    }

    const verbsColRef = collection(db, 'verbs');

    try {

        const q = query(verbsColRef, where('uuid', '==', uuid));

        const querySnapshot = await getDocs(q);

        const document = querySnapshot.docs[0];

        await deleteDoc(document.ref);


    } catch (exception) {
        data.status = FAILED_OPERATION;
        data.error = exception;
    }

    return data;
}


export const updateVerb = async ({uuid, verb}) => {
    const data = {
        status: SUCCESSFUL_OPERATION,
        error: null,
    }

    const verbsColRef = collection(db, 'verbs');

    try {

        const q = query(verbsColRef, where('uuid', '==', uuid));

        const querySnapshot = await getDocs(q);

        const docRef = querySnapshot.docs[0].ref;

        await updateDoc(docRef, {verb: verb});
    } catch (exception) {
        data.status = FAILED_OPERATION;
        data.error = exception;
    }

    return data;

}

