import {
    configureStore
} from "@reduxjs/toolkit";

import {
    pageMessageReducer
} from "./page-message-slice";

import {
    authenticationReducer
} from "./authentication-slice";

const store = configureStore({
    reducer: {
        pageMessage: pageMessageReducer,
        authentication: authenticationReducer,
    }
});

export default store;

