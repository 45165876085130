import React, {
    Fragment,
    useEffect
} from "react";

import {
    Form,
    useActionData,
    useLoaderData, useNavigation,
} from "react-router-dom";

import {
    ToastContainer,
    toast
} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const SignInPage = () => {
    const actionData = useActionData();
    const loaderData = useLoaderData();
    const navigation = useNavigation();

    useEffect(() => {
        if (loaderData?.pageMessage) {
            toast.error(loaderData.pageMessage.message);
        }
    }, [loaderData]);

    const isSubmitting = navigation.state === 'submitting';

    return (
        <Fragment>
            <ToastContainer/>
            <div className='w-screen h-screen bg-[#78abc2] flex justify-center items-center'>
                <div className='bg-white rounded-[10px] flex flex-col md:w-1/3 px-10 py-7 shadow shadow-xl'>
                    <h3 className='text-[25px] font-bold'>Oturum Aç</h3>
                    <div className='my-5'>
                        <Form className='flex flex-col gap-y-5'
                              method='POST'>
                            <div className='flex flex-col gap-y-1'>
                                <label className='ms-2 text-[0.7rem] text-gray-400'
                                       htmlFor='email'>E-posta Adresi</label>
                                <input
                                    className={`w-full px-3 py-2 border rounded-[10px] border-1 focus:border-1 ${(actionData?.validationErrors?.email != null) ? 'focus:outline-red-500' : 'focus:outline-[#78abc2]'} ${(actionData?.validationErrors?.email != null) ? 'bg-red-200' : ''}`}
                                    name='email'
                                    id='email'/>
                                {actionData && actionData.validationErrors && actionData.validationErrors.email && (
                                    <i className='ms-2 text-red-500 text-[10px]'>{actionData.validationErrors.email}</i>
                                )}
                            </div>
                            <div className='flex flex-col gap-y-1'>
                                <label className='ms-2 text-[0.7rem] text-gray-400'
                                       htmlFor='password'>Parola</label>
                                <input
                                    className={`w-full px-3 py-2 border rounded-[10px] border-1 focus:border-1 ${(actionData?.validationErrors?.email != null) ? 'focus:outline-red-500' : 'focus:outline-[#78abc2]'} ${(actionData?.validationErrors?.email != null) ? 'bg-red-200' : ''}`}
                                    name='password'
                                    id='password'
                                    type='password'/>
                                {actionData && actionData.validationErrors && actionData.validationErrors.password && (
                                    <i className='ms-2 text-red-500 text-[10px]'>{actionData.validationErrors.password}</i>
                                )}
                            </div>
                            <div className='w-full'>
                                <button
                                    className='transition-colors ease w-full py-3 bg-[#78abc2] hover:bg-[#3897c2] text-white rounded-[10px] font-bold text-[18px]'
                                    type='submit'>
                                    {isSubmitting ? (<i className='bx bx-loader-circle bx-spin'></i>) : 'Oturum Aç'}
                                </button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default SignInPage;
