import {json, redirect} from "react-router-dom";
import { updateVerb} from "../../services/remote-storage";
import {FAILED_OPERATION, SUCCESSFUL_OPERATION} from "../../constants/operation-status-constants";
import store from "../../store/store";
import {pageMessageActions} from "../../store/page-message-slice";
import {ERROR_MESSAGE, SUCCESS_MESSAGE} from "../../constants/page-message-constants";
import {verbUpdateFormValidation} from "../../services/validation/validations";
import {FORM_INVALID} from "../../constants/validation-constants";
import VerbModel from "../../models/VerbModel";

export const loader = async ({params}) => {
    const verbUUID = params.uuid;

    const result = await VerbModel.getVerb(verbUUID);

    if (result.status === FAILED_OPERATION) {
        store.dispatch(pageMessageActions.setMessage({
            type: ERROR_MESSAGE,
            message: result.error,
            from: 'verb update page',
        }));
        return redirect('..');
    }

    return json({
        verb: result.data
    });
}

export const action = async ({request, params}) => {
    const verbUUID = params.uuid;

    const requestData = await request.formData();
    const formData = {
        verb: requestData.get('verb'),
    };


    const validation = verbUpdateFormValidation(formData);

    if (validation.status === FORM_INVALID) {
        return json({validation});
    }

    const readResult = await VerbModel.getVerb(verbUUID);

    if (readResult.status === FAILED_OPERATION) {
        store.dispatch(pageMessageActions.setMessage({
            type: ERROR_MESSAGE,
            message: 'Bir hata olustu',
            from: 'verb update page',
        }));
        return redirect('..');
    }

    if (formData.verb === readResult.verb) {
        return redirect('..');
    }

    const updateResult = await updateVerb({uuid: verbUUID, ...formData});

    store.dispatch(pageMessageActions.setMessage({
        type: SUCCESS_MESSAGE,
        message: updateResult.status === FAILED_OPERATION ? updateResult.error : 'verb basariyla guncellendi',
        from: 'verb update page',
    }));
    return redirect('..');
}