class PhrasalVerbData {
    constructor({uuid, verbUuid, phrasalVerb}) {
        this.uuid = uuid;
        this.verbUuid = verbUuid;
        this.phrasalVerb = phrasalVerb;
    }

    static fromDocumentSnapshot = (documentSnapshot) => {
        return new PhrasalVerbData({
            uuid: documentSnapshot.data()['uuid'],
            verbUuid: documentSnapshot.data()['verb_uuid'],
            phrasalVerb: documentSnapshot.data()['phrasal_verb'],
        });
    }
}

const instance = Object.freeze(PhrasalVerbData);

export default PhrasalVerbData;