import React, {
    Fragment
} from "react";
import {
    Outlet
} from "react-router-dom";

const MainRoot = () => {
    return (
        <Fragment>
            <Outlet/>
        </Fragment>
    );
}

export default MainRoot;