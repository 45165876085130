import {
    createSlice
} from '@reduxjs/toolkit';

const initialState = {
    message: null,
    type: null,
    from: null,
};

const pageMessageSlice = createSlice({
    name: 'page-message',
    initialState,
    reducers: {
        setMessage: (state, action) => {
            state.message = action.payload.message;
            state.type = action.payload.type;
            state.from = action.payload.from;
        },
        resetMessage: (state) => {
            state.message = null;
            state.type = null;
            state.from = null;
        },
    }
});

export const pageMessageActions = pageMessageSlice.actions;

export const pageMessageReducer = pageMessageSlice.reducer;