import SentenceModel from "../../models/SentenceModel";
import {FAILED_OPERATION} from "../../constants/operation-status-constants";
import {json, redirect} from "react-router-dom";
import {pageMessageActions} from "../../store/page-message-slice";
import store from "../../store/store";
import {ERROR_MESSAGE, INFO_MESSAGE, SUCCESS_MESSAGE} from "../../constants/page-message-constants";
import PhrasalVerbModel from "../../models/PhrasalVerbModel";
import {sentenceUpdateFormValidation} from "../../services/validation/validations";
import {FORM_INVALID} from "../../constants/validation-constants";

export const loader = async ({params}) => {
    const sentenceUuid = params.uuid;

    const readSentenceResult = await SentenceModel.readSentence(sentenceUuid);

    if (readSentenceResult.status === FAILED_OPERATION) {
        store.dispatch(pageMessageActions.setMessage({
            type: ERROR_MESSAGE,
            message: readSentenceResult.error,
            from: 'test',
        }));
        return redirect('..');
    }

    const readPhrasalVerbResult = await PhrasalVerbModel.readPhrasalVerb(readSentenceResult.data.phrasalVerbUuid);

    if (readPhrasalVerbResult.status === FAILED_OPERATION) {
        store.dispatch(pageMessageActions.setMessage({
            type: ERROR_MESSAGE,
            message: readPhrasalVerbResult.error,
            from: 'test',
        }));
        return redirect('..');
    }

    return json({
        sentenceData: readSentenceResult.data,
        phrasalVerbData: readPhrasalVerbResult.data,
    });
}

export const action = async ({request}) => {
    const requestData = await request.formData();
    const formData = {
        uuid: requestData.get('uuid'),
        phrasalVerbUuid: requestData.get('phrasalVerb'),
        en: requestData.get('en'),
        tr: requestData.get('tr'),
    }

    const validation = sentenceUpdateFormValidation(formData);

    if (validation.status === FORM_INVALID) {
        return json({
            validation: validation,
        });
    }

    const readSentenceResult = await SentenceModel.readSentence(formData.uuid);
    if (readSentenceResult.status === FAILED_OPERATION) {
        store.dispatch(pageMessageActions.setMessage({
            type: ERROR_MESSAGE,
            message: readSentenceResult.error,
            from: 'test',
        }));
        return redirect('..');
    }

    if (readSentenceResult.data.en === formData.en && readSentenceResult.data.tr === formData.tr) {
        store.dispatch(pageMessageActions.setMessage({
            type: INFO_MESSAGE,
            message: 'Cümle değiştirilmedi',
            from: 'test',
        }));
        return redirect('..');
    }

    const sentenceUpdateResult = await SentenceModel.updateSentence(formData.uuid, formData);
    store.dispatch(pageMessageActions.setMessage({
        type: (sentenceUpdateResult.status === FAILED_OPERATION) ? ERROR_MESSAGE : SUCCESS_MESSAGE,
        message: (sentenceUpdateResult.status === FAILED_OPERATION) ? sentenceUpdateResult.error : 'Cümle başarıyla güncellendi',
        from: 'test',
    }));

    return redirect('..');
}

