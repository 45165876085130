import React, {Fragment, useState} from 'react';
import DashboardPageLayout from "../layouts/DashboardPageLayout";
import {Form, Link, useActionData, useNavigation} from "react-router-dom";
import {homeDashboardVerbsRoute} from "../routes/route-generators";
import {v4 as uuidv4} from 'uuid';

const VerbCreatePage = () => {
    const actionData = useActionData();
    const navigation = useNavigation();

    const [uuid, setUUID] = useState('');

    const isSubmitting = navigation.state === 'submitting';

    const uuidGenerateButtonHandler = () => {
        const generatedUUID = uuidv4();
        setUUID(generatedUUID);
    }

    return (
        <Fragment>
            <DashboardPageLayout title='User Create'>
                <div className='px-40 py-5'>
                    <Form method='POST'
                          className='flex flex-col gap-y-5'>
                        <div className='flex flex-row justify-between items-center'>
                            <Link
                                className='shadow-md uppercase inline-block px-6 py-2.5 font-medium tracking-wide text-white transition-colors duration-300 transform bg-red-600 rounded-lg hover:bg-red-500 focus:outline-none focus:ring focus:ring-red-300 focus:ring-opacity-80'
                                to={homeDashboardVerbsRoute()}>
                                back
                            </Link>
                            <button
                                className="shadow-md px-6 py-2.5 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80"
                                type='submit'>
                                <div className='flex flex-row justify-center items-center gap-x-2'>
                                <span
                                    className='text-md font-medium uppercase'>{isSubmitting ? (
                                    <i className='bx bx-loader-circle bx-spin'></i>) : 'add'}</span>
                                    <i className='bx bx-plus text-[18px]'></i>
                                </div>
                            </button>
                        </div>

                        <div>
                            <label htmlFor="uuid"
                                   className="block text-sm text-gray-500 dark:text-gray-300">UUID</label>
                            <div className='relative'>
                                <input name='uuid'
                                       id='uuid'
                                       type="text"
                                       placeholder="New uuid here..."
                                       value={uuid}
                                       className="block mt-2 w-full placeholder-gray-400/70 dark:placeholder-gray-500 rounded-lg border border-gray-200 pe-36 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-blue-300"
                                       readOnly={true}/>
                                <button
                                    className='absolute right-1 top-1/2 -translate-y-[50%] bg-blue-500 my-auto py-2 font-bold text-sm px-3 uppercase rounded-lg text-white'
                                    type='button'
                                    onClick={uuidGenerateButtonHandler}>
                                    Generate
                                </button>
                            </div>
                            {actionData && actionData.validation && actionData.validation.errors && actionData.validation.errors.uuid && (
                                <i className='mt-1 ms-3 text-[10px] text-red-500'>{actionData.validation.errors.uuid}</i>
                            )}
                        </div>

                        <div>
                            <label htmlFor="verb"
                                   className="block text-sm text-gray-500 dark:text-gray-300">Verb</label>
                            <input name='verb'
                                   id='verb'
                                   type="text"
                                   placeholder="New verb here..."
                                   className="block mt-2 w-full placeholder-gray-400/70 dark:placeholder-gray-500 rounded-lg border border-gray-200 pe-36 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-blue-300"/>
                            {actionData && actionData.validation && actionData.validation.errors && actionData.validation.errors.verb && (
                                <i className='mt-1 ms-3 text-[10px] text-red-500'>{actionData.validation.errors.verb}</i>
                            )}
                        </div>

                    </Form>
                </div>
            </DashboardPageLayout>
        </Fragment>
    );
};

export default VerbCreatePage;