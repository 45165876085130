import {
    json, redirect,
} from "react-router-dom";

import {
    signInFormValidation
} from "../../services/validation/validations";

import {
    signIn
} from '../../services/auth';

import store from "../../store/store";

import {
    pageMessageActions
} from "../../store/page-message-slice";

import {
    homeAuthSignInRoute, homeDashboardRoute
} from "../route-generators";
import {authenticationActions} from "../../store/authentication-slice";

export const action = async ({request}) => {
    const requestData = await request.formData();

    const formData = {
        email: requestData.get('email'),
        password: requestData.get('password'),
    };

    const validationData = signInFormValidation(formData);

    if (validationData.status === 'INVALID') {
        return json({
            validationErrors: validationData.errors,
        });
    }

    const authData = await signIn(formData);

    if (authData.isAuthenticated === false) {
        store.dispatch(pageMessageActions.setMessage({
            message: authData.error,
            type: 'error',
            from: homeAuthSignInRoute(),
        }));
        return null;
    }

    store.dispatch(authenticationActions.signIn({
        userId: authData.userId,
    }));

    return redirect(homeDashboardRoute());
}

export const loader = () => {
    const pageMessage = store.getState().pageMessage;

    if (pageMessage.message !== null) {
        store.dispatch(pageMessageActions.resetMessage());
        return json({
            pageMessage: pageMessage,
        });
    }

    return null;
}