import React, {
    Fragment
} from "react";
import {
    Link
} from "react-router-dom";
import {
    homeAuthSignInRoute, homeDashboardRoute,
} from "../routes/route-generators";

const NavBar = ({isAuthenticated}) => {
    return (
        <Fragment>
            <div className='w-full flex flex-row items-center justify-between bg-[#78abc2] py-3 px-10 shadow'>
                <div>
                    <span className='text-[30px] font-bold text-white'>verbly</span>
                </div>
                <div>

                </div>
                <div>
                    {isAuthenticated ? (
                            <Link
                                className='transition-all ease inline-block bg-white hover:bg-gray-200 py-2 px-5 rounded-[10px] shadow'
                                to={homeDashboardRoute()}>
                                <span className='text-[#78abc2] font-medium'>Go to Dashboard</span>
                            </Link>
                        ) :
                        (
                            <Link
                                className='transition-all ease inline-block bg-white hover:bg-gray-200 py-2 px-5 rounded-[10px] shadow'
                                to={homeAuthSignInRoute()}>
                                <span className='text-[#78abc2] font-medium'>Sign in</span>
                            </Link>
                        )
                    }
                </div>
            </div>
        </Fragment>
    );
};

export default NavBar;