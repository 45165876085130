import React, {useEffect} from "react";
import DashboardPageLayout from "../layouts/DashboardPageLayout";
import {
    Link, useLoaderData
} from "react-router-dom";
import VerbList from "../components/VerbList";

import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useDispatch, useSelector} from "react-redux";
import {ERROR_MESSAGE, SUCCESS_MESSAGE} from "../constants/page-message-constants";
import {pageMessageActions} from "../store/page-message-slice";
import PageMessageWrapper from "../wrappers/PageMessageWrapper";

const VerbListPage = () => {
    const loaderData = useLoaderData();


    return (
        <DashboardPageLayout title={'verbs'}>
            <PageMessageWrapper>
                <div className='w-full h-full flex flex-col px-40 py-5'>
                    <div className='flex flex-row justify-end'>
                        <Link
                            className="inline-block px-6 py-2.5 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80"
                            to='create'>
                            <div className='flex flex-row justify-center items-center gap-x-2'>
                                <span className='text-md font-medium uppercase'>add verb</span>
                                <i className='bx bx-plus text-[18px]'></i>
                            </div>
                        </Link>
                    </div>
                    <div className="inline-block min-w-full py-2 align-middle">
                        <VerbList verbs={loaderData.verbList}/>
                        {
                            (loaderData.verbList.length === 0) && (
                                <div className='flex flex-row justify-center mt-20'>
                                    <span className='text-[20px] font-bold'>Henüz verb eklenmemiş</span>
                                </div>
                            )
                        }
                    </div>
                </div>
            </PageMessageWrapper>
        </DashboardPageLayout>
    );
};

export default VerbListPage;