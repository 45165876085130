import React, {
    Fragment
} from "react";

import {
    Outlet
} from "react-router-dom"
import DashboardLayout from "../layouts/DashboardLayout";

const DashboardRoot = ({children}) => {
    return (
        <Fragment>
            <DashboardLayout>
                <Outlet/>
            </DashboardLayout>
        </Fragment>
    );
};

export default DashboardRoot;