import {
    Outlet
} from "react-router-dom";

const PhrasalVerbRoot = () => {
    return (
        <Outlet/>
    );
};

export default PhrasalVerbRoot;